<template>
  <page name="Детальная страница логов контента">
    <template v-slot="slotProps">
      <div class="logs-view">
        <tirscript3-loading :loading="isLoading"></tirscript3-loading>
        <div class="detail-method-block">
          <template v-if="item">
            <div class="row detail-method-row">
              <div class="col-6 detail-method-col">
                <div class="key">Источник</div>
              </div>
              <div class="col-6 detail-method-col">
                <div class="prop">
                  {{ item.ServerName }} / {{ item.ProjectName }}
                </div>
                <div
                    v-if="!item.ProjectName && !item.ServerName"
                    class="prop no-info"
                >
                  нет данных
                </div>
              </div>
            </div>

            <div class="row detail-method-row">
              <div class="col-6 detail-method-col">
                <div class="key">Url</div>
              </div>
              <div class="col-6 detail-method-col">
                <div class="prop">{{ item.Url }}</div>
                <div v-if="!item.Url" class="prop no-info">нет данных</div>
              </div>
            </div>

            <div class="row detail-method-row">
              <div class="col-6 detail-method-col">
                <div class="key">DateStart / DateEnd</div>
              </div>
              <div class="col-6 detail-method-col">
                <div class="prop">
                  {{ $filters.IntervalMs(item.DateStart, item.DateEnd) }}
                  <br/><b>{{ $filters.DateFormat(item.DateStart) }}</b> -
                  <b>{{ $filters.DateFormat(item.DateEnd) }}</b>
                </div>
                <div
                    v-if="!item.DateStart && !item.DateEnd"
                    class="prop no-info"
                >
                  нет данных
                </div>
              </div>
            </div>

            <div class="row detail-method-row">
              <div class="col-6 detail-method-col">
                <div class="key">RequestLength</div>
              </div>
              <div class="col-6 detail-method-col">
                <div v-if="item.RequestLength >= 0" class="prop">
                  {{ item.RequestLength }}
                </div>
                <div v-else class="prop no-info">нет данных</div>
              </div>
            </div>

            <div class="row detail-method-row">
              <div class="col-6 detail-method-col">
                <div class="key">ResponseLength</div>
              </div>
              <div class="col-6 detail-method-col">
                <div v-if="item.ResponseLength >= 0" class="prop">
                  {{ item.ResponseLength }}
                </div>
                <div v-else class="prop no-info">нет данных</div>
              </div>
            </div>

            <div class="row detail-method-row">
              <div class="col-6 detail-method-col">
                <div class="key">LogLevel</div>
              </div>
              <div class="col-6 detail-method-col">
                <div class="prop">{{ item.LogLevel }}</div>
                <div v-if="!item.LogLevel" class="prop no-info">нет данных</div>
              </div>
            </div>

            <div class="row detail-method-row">
              <div class="col-6 detail-method-col">
                <div class="key">LogType</div>
              </div>
              <div class="col-6 detail-method-col">
                <div class="prop">{{ item.LogType }}</div>
                <div v-if="!item.LogType" class="prop no-info">нет данных</div>
              </div>
            </div>

            <div class="row detail-method-row">
              <div class="col-6 detail-method-col">
                <div class="key">SessionToken</div>
              </div>
              <div class="col-6 detail-method-col">
                <div class="prop">{{ item.SessionToken }}</div>
                <div v-if="!item.SessionToken" class="prop no-info">
                  нет данных
                </div>
              </div>
            </div>

            <div class="row detail-method-row">
              <div class="col-6 detail-method-col">
                <div class="key">ConnectionId</div>
              </div>
              <div class="col-6 detail-method-col">
                <div class="prop">{{ item.ConnectionId }}</div>
                <div v-if="!item.ConnectionId" class="prop no-info">
                  нет данных
                </div>
              </div>
            </div>

            <div class="row detail-method-row">
              <div class="col-6 detail-method-col">
                <div class="key">UserId</div>
              </div>
              <div class="col-6 detail-method-col">
                <div class="prop">{{ item.UserId }}</div>
                <div v-if="!item.UserId" class="prop no-info">нет данных</div>
              </div>
            </div>

            <div class="row detail-method-row">
              <div class="col-6 detail-method-col">
                <div class="key">Ip</div>
              </div>
              <div class="col-6 detail-method-col">
                <div class="prop">{{ item.Ip }}</div>
                <div v-if="!item.Ip" class="prop no-info">нет данных</div>
              </div>
            </div>

            <div class="row detail-method-row">
              <div class="col-6 detail-method-col">
                <div class="key">ProjectLogGroupId</div>
              </div>
              <div class="col-6 detail-method-col">
                <div class="prop">{{ item.ProjectLogGroupId }}</div>
                <div v-if="!item.ProjectLogGroupId" class="prop no-info">
                  нет данных
                </div>
              </div>
            </div>
          </template>

          <template v-if="itemDataLogs">
            <div class="row detail-method-row">
              <div class="col-6 detail-method-col">
                <div class="key">RequestBody</div>
              </div>
              <div class="col-6 detail-method-col">
                <div class="prop">{{ itemDataLogs.RequestBody }}</div>
                <div v-if="!itemDataLogs.RequestBody" class="prop no-info">
                  нет данных
                </div>
              </div>
            </div>

            <div class="row detail-method-row">
              <div class="col-6 detail-method-col">
                <div class="key">Message</div>
              </div>
              <div class="col-6 detail-method-col">
                <div class="prop">{{ itemDataLogs.Message }}</div>
                <div v-if="!itemDataLogs.Message" class="prop no-info">
                  нет данных
                </div>
              </div>
            </div>

            <div class="row detail-method-row">
              <div class="col-6 detail-method-col">
                <div class="key">Error</div>
              </div>
              <div class="col-6 detail-method-col">
                <div class="prop">{{ itemDataLogs.Error }}</div>
                <div v-if="!itemDataLogs.Error" class="prop no-info">
                  нет данных
                </div>
              </div>
            </div>

            <div class="row detail-method-row">
              <div class="col-6 detail-method-col">
                <div class="key">UserAgent</div>
              </div>
              <div class="col-6 detail-method-col">
                <div class="prop">{{ itemDataLogs.UserAgent }}</div>
                <div v-if="!itemDataLogs.UserAgent" class="prop no-info">
                  нет данных
                </div>
              </div>
            </div>

            <div class="row detail-method-row">
              <div class="col-6 detail-method-col">
                <div class="key">LoggerName</div>
              </div>
              <div class="col-6 detail-method-col">
                <div class="prop">{{ item.LoggerName }}</div>
                <div v-if="!item.LoggerName" class="prop no-info">
                  нет данных
                </div>
              </div>
            </div>

            <div class="row detail-method-row">
              <div class="col-6 detail-method-col">
                <div class="key">ShortMessage</div>
              </div>
              <div class="col-6 detail-method-col">
                <div class="prop">{{ item.ShortMessage }}</div>
                <div v-if="!item.ShortMessage" class="prop no-info">
                  нет данных
                </div>
              </div>
            </div>
          </template>
        </div>
        <div v-if="!item && !itemDataLogs && isLoading == false" class="row">
          <div class="pb-2 pt-4 col-12" style="text-align: center">
            Нет данных
          </div>
        </div>
      </div>
      <!--Сообщение об ошибке-->
      <div
          v-if="!error1.isSuccess"
          class="row mt-2 justify-content-center"
      >
        {{ error1.errorMessage }}
      </div>
      <!--Сообщение об ошибке-->
      <div
          v-if="!error2.isSuccess"
          class="row mt-2 justify-content-center"
      >
        {{ error2.errorMessage }}
      </div>
    </template>
  </page>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";

import Page from "@views/page/components/page.vue";
// import LogsView from "@views/page/logs-view.vue";
// import ContentLogsDetailView from "@views/page/content-logs/components/content-logs-detail-view.vue"
import LogsViewFilter from "@/views/components/filter-component/logs-view-filter.vue";
import PageRequest from "@dataSource/PaginationHelper/Lib/PageRequest";
import FilterModel from "../components/filter-model";
import getUtc from "@/filters/getUtc";
import GetLogDetailsResponse
  from "@/dataSource/api/LoggerMicroService/Services/Models/Response/LogGetterService/GetLogDetailsResponse";
import GetLogDataResponse
  from "@/dataSource/api/LoggerMicroService/Services/Models/Response/LogGetterService/GetLogDataResponse";

@Options<ContentLogsDetailPage>({
  components: {
    Page,
    LogsViewFilter,
    // ContentLogsDetailView
  },
  props: {slotProps: {}},

  watch: {
    //параметры заходящие через слот
    //пока только идентификатор источника логов(sourceLogId)
    slotProps: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value?.sourceLogId) {
          this.filterModel.source = value.sourceLogId;
        }
      },
    },
  },
})
export default class ContentLogsDetailPage extends Vue {
  readonly slotProps = {};
  pagination: PageRequest;
  requestId: string = "";
  item: GetLogDetailsResponse = null;
  itemDataLogs: GetLogDataResponse = null;
  isLoading = false;
  filterModel: FilterModel = new FilterModel();
  filterModelView: FilterModel = null;

  error1 = {
    isSuccess: true,
    errorMessage: ""
  }
  error2 = {
    isSuccess: true,
    errorMessage: ""
  }

  getUtc = getUtc;

  async mounted() {
    // this.getInLocalStor();
    // // подписываемся на событие отфильтровать в компоненте Page
    // document.addEventListener(
    //   "filterChange",
    //   this.onclickFilterChange.bind(this)
    // );
    this.requestId = String(this.$route.params.id);
    //await this.getLogDetails()
    await this.getLogData();
    this.isLoading = false;
    //чтобы скопировать значение, а ссылку  и исключить реактивность. Обновление только по кнопке отфильтровать.
    // this.filterModelView = JSON.parse(JSON.stringify(this.filterModel));
  }

  getLocalStorageInfo() {
    if (localStorage.contentLogsRequestId) {
      this.requestId = localStorage.getItem("contentLogsRequestId");
      this.getLogDetails();
      this.getLogData();
    } else {
      this.$router.back();
    }
  }

  async getLogDetails() {
    this.isLoading = true;
    let request
    try {
      request = await this.$api.LogGetterService.getLogDetailsAsync(
          `"${this.requestId}"`
      );
    } catch (error) {
      this.error1.isSuccess = false;
      this.error1.errorMessage = error;
      this.isLoading = false;
    }

    if (request) this.item = request;
  }

  async getLogData() {
    this.isLoading = true;
    try {
      this.itemDataLogs = await this.$api.LogGetterService.getLogDataAsync(
          `"${this.requestId}"`
      );
    } catch (error) {
      this.error2.isSuccess = false;
      this.error2.errorMessage = error;
      this.isLoading = false;
    }
    this.item = await this.$api.LogGetterService.getLogDetailsAsync(
        `"${this.requestId}"`
    );
  }

  onclickFilterChange() {
    // ловим событие нажатия кнопки отфильтровать
    this.filterModel.DateTo = this.filterModel.DateTo;
    this.filterModel.DateFrom = this.filterModel.DateFrom;
    this.filterModelView = JSON.parse(JSON.stringify(this.filterModel));
    //сохраняем в локал сторедж состояние фильтра для текущей страницы
    this.saveInLocalStor();
  }

  //сохранить модель фильтра для страницы
  saveInLocalStor() {
    localStorage.otherLogsViewPage = JSON.stringify(this.filterModelView);
  }

  //получить данные фильтра для страницы если они есть
  getInLocalStor() {
    if (localStorage.otherLogsViewPage) {
      this.filterModel = JSON.parse(localStorage.otherLogsViewPage);
      const t = new Date().getTimezoneOffset();
      this.filterModel.DateFrom = this.addMinutes(this.filterModel.DateFrom, t);
      this.filterModel.DateTo = this.addMinutes(this.filterModel.DateTo, t);
      // this.filterModel.DateTo = new Date(this.filterModel.DateTo);
    }
  }

  addMinutes(date, minutes) {
    return new Date(new Date(date).getTime() + minutes * 60000);
  }

  //var date = convertUTCDateToLocalDate(new Date(date_string_you_received));
}
</script>

<style lang="less">
.other-logs-view-page {
  .min-width {
    min-width: 150px;
  }
}

.logs-view {
  .detale {
    color: blue;
    cursor: pointer;
  }
}

.logs-view .tirscript3-table .tirscript3-table-header-item,
.logs-view .tirscript3-table .tirscript3-table-body-item {
  justify-content: center;
  text-align: center;
}

.detail-method-row {
  padding: 15px 0;
  margin: 0;
  border-bottom: 1px solid #ced4de;
}

.no-info {
  opacity: 0.5;
}
</style>